@import "../../../common-assets/css/variables";
@import "../../../common-assets/css/rootui-parts/profile";

.cover-photo {
  height: 300px;
  width: 100%;
  background-size: cover;
}

.store_sde-upp1 {
  position: absolute;
  bottom: 0;
  display: none;
  text-align: center;
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  padding: 8px 10px 10px 10px;
  z-index: 1;
  cursor: pointer;
}

.store_sde-upp1.show {
  display: inherit;
}

.rui-dropzone .rui-dropzone-message {
  min-height: 94px !important;
}

.right-actions {
  right: calc(50% - 645px);
}

@media screen and (max-width: 1024px) {
  .right-actions {
    right: 20px;
  }
}

.rui-gmap-small {
  height: 200px;
  min-height: 200px;
}

.rui-gmap-xsmall {
  height: 120px;
  min-height: 120px;
  margin-bottom: 10px;
}
